<template>
  <vz-dialog
    ref="dialog"
    :name="name"
    hide-footer
    hide-header
    hide-offset
    small
    class="vz-success-modal"
    @showed="onShow"
  >
    <div class="vz-success-modal-body">
      <p v-if="title" v-html="title" />
      <vz-icon
        no-masked-icon
        url="/svg/vz-success-thin.svg"
        variant="light-green"
        size="79"
      />
      <p v-html="showText" />
      <slot />
      <vz-button
        v-if="hasButton"
        big
        variant="primary"
        @click="hide"
      >
        Закрыть
      </vz-button>
    </div>
  </vz-dialog>
</template>

<script setup lang="ts">
import VzDialog from '~/components/ui-kit/vz-dialog.vue';
import VzIcon from '~/components/ui-kit/vz-icon.vue';
import VzButton from '~/components/ui-kit/vz-button.vue';

const { $hideDialog } = useNuxtApp()

const props = defineProps({
  name: {
    type: String,
    default: 'vz-success-modal'
  },
  title: {
    type: String,
    default: ''
  },
  text: {
    type: String,
    default: ''
  },
  timeout: {
    type: Number,
    default: 5000
  },
  hasButton: {
    type: Boolean,
    default: false
  }
})

const dialog = ref()
const presetText = ref('')

const showText = computed(() => {
  return props.text || presetText.value
})

function onShow(preset: any) {
  presetText.value = preset.message

  if (props.timeout) {
    setTimeout(() => {
      $hideDialog({ name: props.name })
    }, props.timeout)
  }
}

function show() {
  dialog.value.show()
}

function hide() {
  dialog.value.hide()
}

defineExpose({
  show,
  hide
})
</script>

<style lang="scss" >
.vz-success-modal {
  top: 30%;

  &-body {
    font-size: $fs-20;
    line-height: 23.5px;
    font-weight: 500;
    text-align: center;
    padding: 40px 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 20px;

    .vz-button {
      width: fit-content;
    }
  }
}
</style>
